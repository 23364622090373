<script>
//   import Multiselect from 'vue-multiselect'

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
export default {
    components:{},
    props:['service','ODS','send','pos','estimated','disable','planB'],
    data(){
        return {
            active:false,
            addPersonel:false,
            // fields:[{poste:{poste:"Superviseur",toAsk:false,qte:1},Qte:2,checkQte:2,uom:{designation:'Personne'}}],
            selectedService:[],
            ProductList:[],
            RH:[],
            postes:[],
            EquipmentsList:[],
            EQPListOrdering:[],

        }
    },
    mounted(){
        this.selectedService = {id:this.service.id,service:this.service.servicable,qty:this.service.quantity,amount:this.service.amount,checkQte:this.service.quantity_prest ? this.service.quantity_prest : 0} 
    },
    methods:{
        emitData(){
             this.$emit('getvalues',this.selectedService)
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle="`accordion-${pos+1}`" style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> {{service.servicable.name}}</div>
                            <div class="col text-right">
                                <!-- <span>Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(TotalAmount())}}</span> -->
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse :id="`accordion-${pos+1}`" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg" style="heigth:200px">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="">Quantité Demandé</label>
                                    <input class="form-control" type="number" readonly placeholder="20" v-model="selectedService.qty"  @keypress="isNumber($event)">
                                </div>
                                <div class="col-md-4">
                                    <label for="">Quantité Réaliser</label>
                                    <input type="number" :disabled="ODS.read_at == null"  class="form-control" min="0" v-model="selectedService.checkQte" @input="emitData()" @keypress="isNumber($event)">
                                </div>
                                <!-- <div class="col-md-2  mt-4"> <a class="btn text-danger" @click="deleteProductRow(i)"> <i class="far fa-trash-alt font-size-24"></i> </a> </div> -->
                            </div>
                            <!-- <div class="row mt-3">
                                <div class="col text-right">
                                    <button class="btn btn-info" type="button" @click="AddformDataProduct"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                </div>
                            </div> -->
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>